<template>
  <div>
    <label v-if="label" class="text-xs pl-3 text-left block mb-1">{{ label }} <span class="text-red-500" v-if="asterix">*</span></label>
    <input
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      :class="[
        withIcon ? 'pl-11' : paddingLeft[size],
        fieldClass[size],
        additionalClass,
        { 'text-center': center },
        { 'is-invalid': hasErrors, 'bg-grey cursor-not-allowed': disabled, 'border-grey-field border': borderEnabled, 'bg-white': disabledWhiteBg }
      ]"
      class="focus:outline-none focus:ring-2 focus:yellow w-full border-2 border-yellow-secondary rounded-xl"
      :disabled="disabled || disabledWhiteBg"
      v-on:keyup.enter="enterKeyAction()"
    />
    <div v-if="importantNotes && notes" class="arrow text-xs leading-tight inline-block p-2">{{ notes }}</div>
    <span v-else class="text-xs text-gray-500 mt-1 leading-tight inline-block">{{ notes }}</span>
    <transition name="fade" mode="out-in">
      <div class="text-system-error text-left mt-1 text-xs" v-if="error">{{ error }}</div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    title: String,
    notes: String,
    importantNotes: { type: Boolean, default: false },
    disabled: Boolean,
    center: Boolean,
    asterix: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'regular'
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    enterKeyAction: {
      type: Function,
      default: () => {}
    },
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    additionalClass: {
      type: String,
      default: ''
    },
    disabledWhiteBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fieldClass: {
        small: 'pr-2 py-1',
        regular: 'pr-3 py-3',
        medium: 'pr-3 py-2'
      },
      paddingLeft: {
        small: 'pl-2',
        regular: 'pl-3',
        medium: 'pl-3'
      }
    }
  },
  computed: {
    hasErrors() {
      return !!this.error.length
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.arrow {
  margin-top: 10px;
  background: #fffaf5;
  position: relative;
  cursor: pointer;
  border: 1px solid #fde9d2;
  border-radius: 10px;
}
.arrow:before {
  content: '';
  position: absolute;
  bottom: -webkit-calc(100% - 4px);
  bottom: calc(100% - 4px);
  left: 13px;
  height: 10px;
  width: 10px;
  background: #fffaf5;
  transform: rotate(45deg);
  border-top: inherit;
  border-left: inherit;
  box-shadow: inherit;
}
</style>
